<template>
    <div id="Inventory" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Inventario
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="inventory_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Pieza
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && inventory_data.length > 0">
                <li-table :data="inventory_data" 
                    :allow_headers="inventory_headers"
                    pagination_off>
                    <template v-slot:service_life="data">
                        {{ data.value }}%
                    </template>
                    <template v-slot:acquisition_cost="data">
                        ${{ formatAmount(data.value, 2) }}
                    </template>
                    <template v-slot:repair_cost="data">
                        ${{ formatAmount(data.value, 2) }}
                    </template>
                    <template v-slot:ready_for_use="data">
                        {{ data.value == 1 ? 'SI' : 'NO' }}
                    </template>
                    <template v-slot:ready="data">
                        <button @click.stop="selectPart('ready', data.item)"
                            class="btn btn-outline-primary btn-pill  py-0 px-3 inter-bold"
                            style="width: 150px;"
                            :disabled="data.item.ready_for_use == 1"
                            type="button">
                            Autorizar uso
                        </button>
                    </template>
                    <template v-slot:repaired="data">
                        <button @click.stop="selectPart('repair', data.item)"
                            class="btn btn-outline-primary btn-pill  py-0 px-3 inter-bold"
                            style="width: 180px;"
                            :disabled="data.item.part_condition != 'damaged'"
                            type="button">
                            Datos Reparación
                        </button>
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectPart('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectPart('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectPart('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && inventory_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="inventory_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3 v-if="!selected_id">
                        Añadir Pieza
                    </h3>
                    <h3 v-if="selected_id">
                        Editar Pieza
                    </h3>
                </div>

                <div class="col-6">
                    <li-checkbox
                        label="Proviene de un vehiculo:"
                        theme="dark"
                        variant="info"
                        v-model="origin_from_vehicle"
                        >
                    </li-checkbox>
                </div>

                <div class="col-12">
                    <li-select label="Refacción:"
                        class="pl-1"
                        variant="secondary"
                        :options="spare_part_options"
                        full
                        v-model="id_spare_part">
                    </li-select>
                    <span class="text-danger" v-if="errors.id_spare_part">
                        {{ errors.id_spare_part}}
                    </span>
                </div>

                <div class="col-12" v-if="origin_from_vehicle">
                    <li-select label="Vehículo:"
                        class="pl-1"
                        variant="secondary"
                        :options="vehicle_options"
                        full
                        v-model="id_vehicle">
                    </li-select>
                    <span class="text-danger" v-if="errors.id_vehicle">
                        {{ errors.id_vehicle }}
                    </span>
                </div>

                <div class="col-12 col-md-4" v-if="!origin_from_vehicle">
                    <li-input label="Número de serie del vehiculo:"
                        type="text"
                        placeholder="Número de serie"
                        v-model="serial_number"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.serial_number">
                        {{ errors.serial_number }}
                    </span>
                </div>

                <div class="col-12 col-md-4" v-if="!origin_from_vehicle">
                    <li-select label="Modelo:"
                        class="pl-1"
                        variant="secondary"
                        :options="model_options"
                        full
                        v-model="id_model">
                    </li-select>
                    <span class="text-dange" v-if="errors.id_model">
                        {{ errors.id_model}}
                    </span>
                </div>

                <div class="col-12 col-md-4" v-if="!origin_from_vehicle">
                    <li-input label="Año:"
                        type="number"
                        placeholder="Año"
                        v-model="year"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.year">
                        {{ errors.year}}
                    </span>
                </div>

                <div class="col-12 col-md-4">
                    <li-input label="Costo de adquisición:"
                        type="number"
                        placeholder="Costo de adquisición"
                        v-model="adquisition_cost"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.adquisition_cost">
                        {{ errors.adquisition_cost}}
                    </span>
                </div>

                <div class="col-12 col-md-4">
                    <li-input label="Costo de reparación:"
                        type="number"
                        placeholder="Costo de reparación"
                        v-model="repair_cost"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.repair_cost">
                        {{ errors.repair_cost}}
                    </span>
                </div>

                <div class="col-12 col-md-4">
                    <li-input label="Condiciones de servicio máximo(100):"
                        type="number"
                        placeholder="Condiciónes de servicio"
                        v-model="service_life"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.service_life">
                        {{ errors.service_life }}
                    </span>
                </div>

                <div class="col-12 col-md-4">
                    <li-select label="Codición de la pieza:"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: 'new', label: 'Nueva'},{id: 'damaged', label: 'Dañada'},{id: 'repaired', label: 'Reparada'},{id: 'used', label: 'Segunda mano'}]"
                        full
                        v-model="condition">
                    </li-select>
                </div>

                <div class="col-12">
                    <hr class="border border-secondary w-100">
                    <p>
                        NOTA: Si desconoces el número de serie del vehiculo donde proveniene la pieza, ingresa el siguiente número: <strong>S-00</strong>
                        </p>
                    <p>
                        NOTA: Por motivo de control solo se te pemirte editar los datos solo una vez. Por lo que antes de registrar una refacción asegurate de que los datos sean los correctos.
                        </p>
                </div>

                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="createEditInventory"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearData"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de eliminar esta pieza? 
                        <br>
                        Esta acción no puede revertirse
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteInventory"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar esta pieza? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activateInventory"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="activate_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="repair_modal" :max_width="600" class="px-3">

                <div class="col-12">
                    <li-input label="Costo de reparación:"
                        type="number"
                        placeholder="Costo de reparación"
                        v-model="repair_cost"
                        full>
                    </li-input>
                </div>

                <div class="col-12">
                    <li-input label="Condiciones de servicio máximo(100):"
                        type="number"
                        placeholder="Condiciónes de servicio"
                        v-model="service_life"
                        full>
                    </li-input>
                </div>
                

                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="setRepair"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="repair_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="ready_modal" :max_width="600" class="px-3">
                      
                <div class="col-12">
                    <p>
                        ¿Autorizar refacción para su uso?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="setReady"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="ready_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>
        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                inventory_modal: false,
                delete_modal: false,
                activate_modal: false,
                repair_modal: false,
                ready_modal: false,
                inventory_headers: [
                    { key: 'spare_part_name', label: 'Refacción'},
                    { key: 'model_name', label: 'Modelo'},
                    { key: 'year_originating', label: 'Año'},
                    { key: 'serial_number_originating', label: 'No. Serie'},
                    { key: 'acquisition_cost', label: 'Costo de adquision'},
                    { key: 'repair_cost', label: 'C. Reparación'},
                    { key: 'service_life', label: 'Condiciones de servicio'},
                    { key: 'ready_for_use', label: 'Autorizado para uso'},
                    { key: 'ready', width: '10'},
                    { key: 'repaired', width: '10'},
                    { key: 'delete', width: '10'},
                    { key: 'edit', width: '10'},
                ],
                inventory_data: [],
                spare_part_options: [],
                model_options: [],
                vehicle_options: [],
                id_spare_part: null,
                id_model: null,
                id_vehicle: null,
                serial_number: null,
                year: null,
                condition: 'new',
                service_life: null,
                adquisition_cost: null,
                repair_cost: null,
                selected_id: null,
                origin_from_vehicle: false,
                errors: {
                    id_spare_part: null,
                    id_model: null,
                    id_vehicle: null,
                    serial_number: null,
                    year: null,
                    service_life: null,
                    adquisition_cost: null,
                    repair_cost: null,
                },
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        computed: {
            ...mapGetters({
                parts: 'inventory/getParts',
                part: 'inventory/getPart',
                last_page: 'inventory/getLastPage',
                total_pages: 'inventory/getTotalPages',
                spare_parts: 'spare_parts/getSpareParts',
                vehicles: 'unit_administration/getUnits',
                models: 'models/getModels'
            })
        },
        methods: {
            //helpers
            clearData() {
                this.id_spare_part = null
                this.id_model = null
                this.id_vehicle = null
                this.serial_number = null
                this.year = null
                this.condition = 'new'
                this.service_life = null
                this.adquisition_cost = null
                this.repair_cost = null
                this.inventory_modal = false; 
                this.selected_id = null;
                this.delete_modal = false;
                this.activate_modal = false
                this.repair_modal = false;
                this.ready_modal = false;
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('inventory/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('inventory/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('inventory/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.parts === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.inventory_data = this.parts.map( part => {
                    return {
                        ...part,
                        ready: '-',
                        repaired: '-',
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            async selectPart(type, data) {
                switch(type) {
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_part_inventory
                        await this.$store.dispatch('inventory/view', { id_part_inventory: this.selected_id})

                        if(this.part.id_vehicle_originating) {
                            this.id_vehicle = this.part.id_vehicle_originating
                            this.origin_from_vehicle = true
                        }

                        else {
                            this.id_model = this.part.id_model_originating
                            this.serial_number = this.part.serial_number_originating
                            this.year = this.part.year_originating
                        }

                        this.condition = this.part.part_condition
                        this.adquisition_cost = this.part.acquisition_cost
                        this.repair_cost = this.part.repair_cost
                        this.service_life = this.part.service_life
                        this.id_spare_part = this.part.id_spare_part

                        this.inventory_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_part_inventory
                        this.delete_modal = true
                        return;
                    case 'activate':
                        this.selected_id = data.id_part_inventory
                        this.activate_modal = true
                        return;
                    case 'repair':
                        this.selected_id = data.id_part_inventory
                        this.repair_modal = true;
                        break;
                    case 'ready':
                        this.selected_id = data.id_part_inventory
                        this.ready_modal = true;
                        break;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditInventory() {

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(this.origin_from_vehicle && _.isNull(this.id_vehicle)) {
                    complete = false
                    this.errors['id_vehicle'] = '*Campo obligatorio'
                }

                if(!this.origin_from_vehicle && _.isNull(this.id_model)) {
                    complete = false;
                    this.errors['id_vehicle'] = '*Campo obligatorio'
                }

                if(!this.origin_from_vehicle && _.isNull(this.serial_number)) {
                    complete = false;
                    this.errors['serial_number'] = '*Campo obligatorio'
                }

                if(!this.origin_from_vehicle && _.isNull(this.year)) {
                    complete = false;
                    this.errors['year'] = '*Campo obligatorio'
                }

                if(_.isNull(this.adquisition_cost)) {
                    complete = false;
                    this.errors['adquisition_cost'] = '*Campo obligatorio'
                }

                if(_.isNull(this.repair_cost)) {
                    complete = false;
                    this.errors['adquisition_cost'] = '*Campo obligatorio'
                }

                if(_.isNull(this.service_life) || this.service_life > 100) {
                    complete = false;
                    this.errors['service_life'] = '*Campo obligatorio'
                }

                if(complete) {
                    const payload = {
                        id_spare_part: this.id_spare_part,
                        acquisition_cost: this.formatAmount(this.adquisition_cost),
                        repair_cost: this.formatAmount(this.repair_cost),
                        service_life: this.service_life,
                        part_condition: this.condition,
                        ready_for_use: false
                    }

                    if(!this.origin_from_vehicle) {
                        payload.serial_number_originating = this.serial_number
                        payload.id_model_originating = this.id_model
                        payload.year_originating = this.year
                    }

                    else {
                        payload.id_vehicle_originating = this.id_vehicle
                    }

                    if(this.selected_id) {
                        let data = {
                            query: {
                                id_part_inventory: this.selected_id
                            },
                            body: payload
                        }
                        
                        await this.$store.dispatch('inventory/update', data)
                        await this.filterElements()
                        this.inventory_modal = false
                        this.selected_id = null
                        this.clearData()
                    }

                    else {
                        await this.$store.dispatch('inventory/add', payload)
                        await this.filterElements()

                        this.inventory_modal = false
                        this.clearData()
                        
                    }
                }
            },
            async deleteInventory() {
                await this.$store.dispatch('inventory/delete', { id_part_inventory: this.selected_id, active: false})
                this.filterElements()

                this.delete_modal = false
                this.selected_id = null
            },
            async activateInventory(){
                await this.$store.dispatch('inventory/delete', { id_part_inventory: this.selected_id, active: true})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },
            async setRepair(){
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true;

                if(_.isNull(this.repair_cost)) {
                    this.errors['repair_cost'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isNull(this.service_life) || this.service_life > 100) {
                    this.errors['service_life'] = '*Campo obligatorio'
                    complete = false
                }

                if(complete) {
                    let payload = {
                        query: {
                            id_part_inventory: this.selected_id
                        },
                        body: {
                            repair_cost: this.formatAmount(this.repair_cost),
                            service_life: this.service_life
                        }
                    }

                    await this.$store.dispatch('inventory/addRepair', payload);
                    await this.filterElements()
                    this.clearData()
                }
            },
            async setReady() {
                await this.$store.dispatch('inventory/setReady', { id_part_inventory: this.selected_id})
                await this.filterElements()
                this.clearData()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {
                //lista de partes en inventario
                await this.$store.dispatch('inventory/list', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false})
                
                this.inventory_data = this.parts.map( part => {
                    return {
                        ...part,
                        ready: '-',
                        repaired: '-',
                        edit: '-',
                        delete: '-'
                    }
                })

                //lista de piezas disponible
                await this.$store.dispatch('spare_parts/listSpareParts', {datatable: false, status: 'active'});
                this.spare_part_options = this.spare_parts.map((part) => {
                    return {
                        id: part.id_spare_part,
                        label: part.name
                    }
                })

                //lista de vehiculos
                await this.$store.dispatch('unit_administration/listVehicles', {datatable: false, status: 'active'});
                this.vehicle_options = this.vehicles.map((vehicle) => {
                    return {
                        id:vehicle.id_vehicle,
                        label: `${vehicle.serial_number} ${vehicle.plates ?? 'Sin placas'}`
                    }
                })

                //lista de modelos
                await this.$store.dispatch('models/listModels', {datatable: false, status: 'active'});
                this.model_options = this.models.map((model) => {
                    return {
                        id: model.id_model,
                        label: model.name
                    }
                })

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Inventory {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>